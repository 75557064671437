import {Button, CircularProgress, Container, Grid, Stack, Typography, useTheme} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import * as React from "react";
import {firestore} from "../firebase/clientApp";
import {useDocument} from "react-firebase-hooks/firestore";
import {version} from "../config";

const ScegliTavolo = () => {

    const theme = useTheme();
    let {event} = useParams();
    const docRef = firestore.doc('events/' + event);
    const [config, configLoading, configError] = useDocument(docRef);

    return <Stack sx={{
        background: theme.palette.background.main,
        width: '100%', minHeight: '50vh', paddingBottom: '2rem'
    }} direction={"column"}
                  justifyContent={"center"} alignItems={"center"}>
        <Container maxWidth={'xl'}>

            {configLoading && !configError &&
                <Stack direction={"column"} alignItems={"center"}>
                    <CircularProgress variant={"indeterminate"}/>
                </Stack>
            }

            {!configLoading && !configError && <Stack direction={"column"} sx={{px: 5, pt: 5}} alignItems={"center"}>
                <img src={config.data().url_logo} style={{maxWidth: '10rem'}}/>

                <Typography component={"h2"} color={theme.palette.primary.main} variant={"h4"} sx={{mt: 5}}>
                    Choose your table
                </Typography>

                <Grid container alignItems={"center"} justifyContent={"center"} spacing={4} sx={{my: 4}}>
                    {!configLoading && !configError && [...Array(parseInt(config.data().total_tables)).keys()].map((numero) =>
                        <Grid key={numero} item xs={6} sm={3} md={2} justifyContent={"center"} sx={{textAlign: "center"}}>
                            <Button sx={{width: '4rem', height: '4rem'}} size={"large"} component={Link}
                                    to={'/' + event + '/table/' + (numero + 1)} variant={"outlined"}>
                                <Typography variant={"h4"}>{(numero + 1)}</Typography>
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Stack>}

            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"flex-end"}>
                <Typography variant={"body2"}>v. {version}</Typography>
            </Stack>
        </Container>
    </Stack>
}

export default ScegliTavolo;
