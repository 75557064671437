import React from 'react';
import './App.css';
import {CssBaseline} from "@mui/material";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import ScegliTavolo from "./pages/ScegliTavolo";
import {ScegliEvento} from "./pages/ScegliEvento";

function App() {
    return (
        <div>
            <CssBaseline/>
            <Routes>
                <Route path={'/'} element={<Navigate to={'/ceip-2'}/>}/>
                <Route path={'/:event/table/:table'} element={<Home/>}/>
                <Route path={'/:event'} element={<ScegliTavolo/>}/>
            </Routes>
        </div>

    );
}

export default App;
